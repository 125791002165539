import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('hidden.bs.collapse', event => {
      const form = this.element.querySelector("form");
      const checkboxes = form.querySelectorAll('input[type="checkbox"]')
      let changed = false;
      checkboxes.forEach((item) => {
	if (item.checked) {
	  item.checked = false;
	  changed = true;
	}
      });
      if (changed) form.requestSubmit();
    });
  }

}
