import { Controller } from "@hotwired/stimulus"

import "leaflet/dist/leaflet.css";
require('leaflet');

export default class extends Controller {

  connect() {
    let lat  = this.element.dataset.lat;
    let lng  = this.element.dataset.lng;
    let zoom = this.element.dataset.zoom;
    let map = L.map(this.element.id).setView([lat, lng], zoom);
    L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright" title="OpenStreetMap" target="_blank">OpenStreetMap</a> contributors',
      subdomains: ['a','b','c']
    }).addTo(map);
    var markerIcon = L.icon({
      iconUrl:   imagePath("./leaflet/marker-icon.png"),
      shadowUrl: imagePath("./leaflet/marker-shadow.png"),

      iconSize:     [25, 41],
      iconAnchor:   [12, 41],
      shadowSize:   [41, 41],
      shadowAnchor: [12, 41]
    });
    L.marker([lat, lng], { icon: markerIcon }).addTo(map);
  }

}
