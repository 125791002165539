"use strict";
import CookieConsentForm from "./cookie-consent";

const texts = {
  h1: {
    de: 'Cookie-Einstellungen',
  },
  '#cookie-consent-details-button' : {
    de: 'Details',
  },
  '#cookie-consent-links' : {
    de: '<a href="/freiburg/data_protection">Datenschutz</a> | <a href="/freiburg/imprint">Impressum</a>',
  },
  p: {
    de: 'Die Mitmachbörse verwendet Cookies um Inhalte zu personalisieren, sowie die Zugriffe auf die Website zu analysieren. Sie können selbst entscheiden welche Funktionen zugelassen werden sollen. Ihre Einstellungen können Sie jederzeit über den Link <i>Cookie-Einstellungen</i> im Fuß der Website ändern.',
  },
  '[value="allow_selection"]': {
    de: 'Auswahl speichern',
  },
  '[value="allow_all"]': {
    de: 'Alle Cookies zulassen',
  },
  '#cookie-consent-details table thead' : {
    de: '<th>Kategorie</th><th>Beschreibung</th>',
  },
};
const categories = {
  necessary: {
    mandatory: true,
    label:  {
      de: 'Notwendig',
    },
    title: {
      de: 'Diese Cookies sind notwendig für die Funktion der Website.',
    },
    text: {
      de: 'Diese Cookies sind notwendig für unsere Website. Wir verwenden ein Sitzungs-Cookie, einen für die Cookie-Einstellungen, sowie die Analyse-Plattform Matomo zur kontinuierlichen Verbesserung unserer Dienste (Ihre Daten werden nicht an Dritte weitergegeben).',
    }
  },
};

function init() {
  let cookieConsentForm = new CookieConsentForm(
    document.getElementById('cookie-consent-popup'),
    {
      cookie_name: "cookie_consent",
      texts: texts,
      categories: categories,
      cookie_attrs: {
	"Max-Age" : 60 * 60 * 24 * 365 * 5, // 5 years
	"SameSite" : "Strict",
	"Path" : "/",
      },
      hide_on_pages: ['/freiburg/data_protection',
		      '/freiburg/imprint'],
      html_template: '<div id="cookie-consent-inner"><form><h1></h1><p></p><div id="cookie-consent-checkboxes"></div><div id="cookie-consent-buttons" class="float-start"><button type="submit" value="allow_selection" class="btn btn-primary"></button> <button type="submit" value="allow_all" class="btn btn-success"></button></div> <div id="cookie-consent-details-div" class="float-end"><a href="#" id="cookie-consent-details-button" class="btn"></a></div><div class="clearfix"></div><div id="cookie-consent-details"><table class="table"><thead></thead><tbody></tbody></table></div><div id="cookie-consent-links"></div></form></div><div id="cookie-consent-backdrop"></div>'
    }
  );
  return cookieConsentForm;
};

export default init;
