function init() {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map(
    element => {
      let tooltip = new bootstrap.Tooltip(element);
      element.addEventListener("click", element => tooltip.hide());
    }
  )
}

module.exports = { init }
