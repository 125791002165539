import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("click", (event) => {
      event.preventDefault();
      let form = document.getElementById(this.element.dataset.target);
      if (form.classList.contains("is-open"))
	form.classList.remove("is-open")
      else
	form.classList.add("is-open")
    });
  }

}
