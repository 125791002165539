import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const project_filter = document.getElementById('project-filter');
    const checkboxes = project_filter.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((item) => {
      const tags = this.element.querySelectorAll("." + item.id);
      tags.forEach((tag) => {
	if (item.checked)
	  tag.classList.add("is-active");
	else
	  tag.classList.remove("is-active");
      });
    });
  }

}
